@use "sass:math";

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light_grey;
}

$avatar_sizes: (
  'small': 22px,
  'default': 36px,
  'medium': 40px,
  'big': 120px
);

@mixin userAvatar($size) {
  max-width: $size;
  min-width: $size;
  width: $size;
  height: $size;
  border-radius: math.div($size, 2);

  svg {
    width: round(math.div($size, 1.7));
    height: round(math.div($size, 1.7));
  }

  .avatar-letters {
    font-weight: 600;
    color: $basic_grey;
  }

  .avatar-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: math.div($size, 2);
    overflow: hidden;
  }
}

@each $name, $size in $avatar_sizes {
  .avatar-#{$name} {
    @include userAvatar($size);
  }
}
