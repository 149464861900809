@mixin placeHolderBackgournd {
  position: relative;
  background: #ebebeb;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 90%;
    height: inherit;
    background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(219, 219, 219, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    animation: placeHolderShimmer 1.5s linear infinite;
    will-change: transform;
  }
}

@keyframes placeHolderShimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(300%);
  }
}
