// font
$font_family: 'Roboto', Arial, sans-serif;
$font_color_base: #4a4a4a;

$fonts_path: '/assets/fonts';

// init font-icon
$icons_family: 'icomoon';
$icon_prefix: 'icon-';
$icon_key: '?e9aqbh';

// colors
$black: #000;
$white: #fff;
$green: #03d603;
$orange: #ed9107;

$basic_grey: #777;
$light_grey: #f3f3f3;
$inactive_color: #979797;
$darken_grey: #4a4a4a;

$light_title: #eaeff4;
$darken_label: #7c757f;

$bg_color: #f9f9f9;

$border_color: #ececec;
$border_radius: 3px;
$border_card_radius: 6px;

$comment_color: #ff7472;
$comment_message: #f3f3f3;

$pdf_primary_color: #4a4a4a;

$info: #0079ff;
$alert: #ff7472;
$success: #1aaa55;
$warning: #fc6d26;
$error: #ff3838;
$caption: #9b9b9b;
$tree_item: #111528;

// workspace items
$workspace_item_size: 40px;
$item_color: #4a4a4a;
$item_label_color: #b2b2b2;

// header
$header_height: 60px;

// zindex
$depth: (
  'header': 200,
  'footer': 200,
  'aside': 5,
  'content': 0,
  'toast': 5000,
  'overlay': 500,
  'modal': 1000,
  'tooltip': 5000
);

$logoCappuccine-color: #ff6255;
$logoDarkCappuccine-color: #d93a23;
$logoOrange-color: #f6ae8e;
$logoBlue-color: #19b4b4;
$logoWorkflow-color: #35bab8;

$bezier: cubic-bezier(0.86, 0, 0.07, 1);
$curve: cubic-bezier(0.65, 0, 0.45, 1);
$bounce: cubic-bezier(0.86, 0, 0.07, 1.3);
$rebounce: cubic-bezier(0.13, 0.78, 0.13, 1.3);

// CSS Level 4 for Theming
:root {
  // --accent_color: #0dc9e9;
  // --accent_color: #50e3c2;
  // --accent_color: #4750ff;
  //--accent_color: #0079ff;
  //--primary_color: #2c2232;
  //--secondary_color: #362f2f;
  --rgb_accent_color: 255, 98, 85;
  --rgb_workflow_color: 53, 186, 184;
  --accent_color: #ff6255;
  --primary_color: #20222d;
  --secondary_color: #14151e;
  --in_edition_color: rgba(17, 192, 137, 1);
  --in_edition_color_light: rgba(207, 242, 231, 1);
  --in_edition_color_btns: rgba(17, 192, 137, 0.2);
  --version_color: rgba(77, 78, 87, 1);
  --version_color_light: #fff;
  --version_color_btns: rgba(77, 78, 87, 1);
  --workflow_color: #35bab8;
}

// SASS VARIABLES TESTS
@import './variables.spec';

$screen-phone: 640px;
$screen-tablet: 768px;
$screen-desktop: 1024px;
