@font-face {
  font-family: 'Roboto-light';
  src: url('#{$fonts_path}/Roboto/Roboto-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}/Roboto/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('#{$fonts_path}/Roboto/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('#{$fonts_path}/Roboto/Roboto-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
