app-header {
  app-avatar {
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

app-mobile-header {
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  grid-template-areas: "navburger logo domain";

  app-mobile-button-menu,
  app-logo-nexia,
  app-mobile-button-domain-selection {
    justify-self: center;
    align-self: center;
  }

  app-mobile-button-menu {
    grid-area: navburger;
  }

  app-logo-nexia {
    grid-area: logo;
  }

  app-mobile-button-domain-selection {
    grid-area: domain;
  }
}
