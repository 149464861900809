@mixin mq ($type, $value) {
  @if isMinOrMax($type) == false {
    @error "$type must be min or max";
  }

  @if type-of($value) != number and unitless($value) {
    @error "$value must be a number + px";
  }

  @media only screen and (#{$type}-width: #{$value}) {
    @content;
  }
}

@mixin max-screen($value) {
  @include mq(max, $value) {
    @content;
  }
}

@mixin min-screen($value) {
  @include mq(min, $value) {
    @content;
  }
}

@mixin max-height($value) {
  @if type-of($value) != number and unitless($value) {
    @error "$value must be a number + px";
  }

  @media only screen and (max-height: #{$value}) {
    @content;
  }
}

@mixin between($max, $min) {
  @if type-of($max) != number and type-of($min) != number and unitless($max) and unitless($min) {
    @error "$max and $min must be a numbers + px";
  }

  @media (max-width: $max) and (min-width: $min) {
    @content;
  }
}
