.indexationInit-label {
  color: $inactive_color;
}

.mat-form-field {
  width: 100%;

  .mat-form-field-infix {
    width: 100%;
    height: 39px;
    margin-top: 8px;
    background-color: $white;
    border-top: 0;
    border-radius: $border_radius;
  }

  .mat-input-element {
    height: 100%;
    padding: 0 10px;
    line-height: 39px;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-bottom: 15px;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
  color: inherit;
  background: transparent;
}

.mat-datepicker-toggle {
  .mat-icon-button {
    background: none;

    svg {
      fill: $basic_grey;
    }
  }
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle {
  color: $white;
}

.mat-calendar-controls {
  button {
    color: $font_color_base;
    background: none;
  }
}

.mat-calendar-next-button::after,
.mat-calendar-previous-button::after {
  width: 14px;
  height: 14px;
  margin: auto !important;
  color: $font_color_base;
}

.mat-checkbox-checked {
  &.mat-accent {
    .mat-checkbox-background {
      background-color: $logoCappuccine-color;
    }
  }

  &.checked {
    &.mat-checkbox-disabled {
      .mat-checkbox-label {
        color: $black;
      }
    }
  }
}

.mat-checkbox-indeterminate {
  &.mat-accent {
    .mat-checkbox-background {
      background-color: $logoCappuccine-color;
    }
  }
}

.mat-checkbox {
  .mat-checkbox-ripple {
    pointer-events: auto !important;
  }
}

button {
  &.mat-sort-header-button {
    font-size: 14px;
    color: $darken_grey;
  }
}

.mat-paginator-page-size {
  margin-top: 10px;

  .mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.mat-paginator {
  background: #fff;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 12px;
}

.mat-table {
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell,
.mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

mat-footer-row,
mat-header-row,
mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-select-panel {
  background: #fff;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-radio-container,
.mat-radio-outer-circle,
.mat-radio-inner-circle {
  width: 15px !important;
  height: 15px !important;
}

.mat-radio-button {
  &.mat-accent {
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $logoCappuccine-color !important;
      }
    }
  }
}

.mat-radio-button {
  &.mat-accent {
    .mat-radio-inner-circle {
      background-color: $logoCappuccine-color !important;
    }
  }
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slide-toggle-thumb {
  background-color: #fafafa;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: $logoCappuccine-color !important;
    }
  }
}

.mat-autocomplete-panel {
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
}

mat-dialog-container {
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-select-arrow {
  color: $logoCappuccine-color !important;
}

.mat-select-panel-wrap {
  padding-top: 25px;
}

//.mat-select-panel {
//  min-width: 65px !important;
//}

.mat-selected {
  background-color: rgba(var(--rgb_accent_color), 0.08) !important;
}
