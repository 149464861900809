.viewMoreLess {
  margin: 10px 10px 10px 0;
  color: var(--accent_color);
  cursor: pointer;

  &.loadMore {
    display: inline-block;
    margin: 10px 10px 10px 30px;
  }
}
