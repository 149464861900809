.form-group {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;

  &-force-one-column {
    flex-direction: column;
  }
}

.form-input {
  position: relative;
  width: 100%;

  &-split {
    width: 45%;
  }
}

.label-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40px;

  .label {
    margin-bottom: 8px;
    color: $inactive_color;
  }

  input {
    width: 100%;
    height: 39px;
    padding: 0 10px;
    background-color: $white;
    border: none;
    border-top: 0;
    border-radius: $border_radius;
  }

  .labelAndBtn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.form-error {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 0.5rem 1.2rem;
  color: $error;
  background-color: rgba($error, 0.2);
  border-radius: $border_radius;
}
