.document-comment-item {
  &#tempId {
    max-width: 100%;

    app-comment {
      // max-width: 80%;
      // width: 460px;

      .comment-content {
        flex: 1 0 100%;
        max-width: 100%;
      }
    }
  }
}
