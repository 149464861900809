h1 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.2;
  @include min-screen($screen-tablet) {
    font-size: 30px;
  }
  @include min-screen($screen-desktop) {
    font-size: 36px;
  }
}

h2 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  @include min-screen($screen-tablet) {
    font-size: 24px;
  }
  @include min-screen($screen-desktop) {
    font-size: 28px;
  }
}

h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  @include min-screen($screen-tablet) {
    font-size: 20px;
  }
  @include min-screen($screen-desktop) {
    font-size: 24px;
  }
}

h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  @include min-screen($screen-desktop) {
    font-size: 16px;
  }
}

h5 {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  @include min-screen($screen-desktop) {
    font-size: 16px;
  }
}

p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  @include min-screen($screen-desktop) {
    font-size: 14px;
  }

  &.caption {
    color: $caption;
  }
}

.small {
  font-size: 10px;
  font-weight: 300;
  color: $basic_grey;

  @include min-screen($screen-desktop) {
    font-size: 12px;
  }

  &-italic {
    font-style: italic;
  }
}

.bold {
  font-weight: 900;
}

.underline {
  text-decoration: underline;
}

.accent {
  color: var(--accent_color);
}

.label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

@mixin textAlert {
  &.alert {
    color: $error;
  }
}
