* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
  text-size-adjust: 100%;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  vertical-align: middle;
  font-weight: normal;
  text-align: left;
}

q,
blockquote {
  quotes: none;

  &::before,
  &::after {
    content: "";
    content: none;
  }
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

audio,
video,
canvas,
progress,
caption,
legend {
  display: inline-block;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  margin: 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  img {
    border: none;
  }
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  outline: 0;

  &:disabled {
    cursor: default !important;
  }
}

button,
input {
  line-height: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizelegibility;
}

a {
  &:hover {
    cursor: pointer;
  }
}
