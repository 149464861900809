.versionMode {
  .comments {
    button,
    a.button {
      &:not(.btn-flat, .mat-sort-header-button, .mat-icon-button, .btn-tree) {
        background-color: var(--accent_color);
      }
    }
  }
}

mat-datepicker-toggle > button.mdc-icon-button {
  vertical-align: -12px;
  height: 40px !important;
  width: 40px !important;
  background-color: rgba(0, 0, 0, 0);
}

mat-datepicker-toggle > .mat-mdc-icon-button svg {
  fill: #777;
  vertical-align: top;
}

button,
a.button {
  position: relative;
  display: table;
  height: 38px;
  // margin-bottom: 10px;
  padding: 0 1.2rem;
  font-size: 14px;
  line-height: 36px;
  color: var(--accent_color);
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: $border_radius;

  &:not(.btn-flat, .mat-sort-header-button, .mat-icon-button, .btn-tree, .combo-btn) {
    color: $white;
    background-color: var(--accent_color);
    border-radius: $border_radius;

    .versionMode & {
      background-color: var(--version_color_btns);
    }

    .editionMode & {
      background-color: var(--in_edition_color_btns);
    }
  }

  &.mat-calendar-body-cell {
    background-color: rgba(0, 0, 0, 0);
  }

  &.btn-tree {
    color: $inactive_color;
    background: none;

    &.mat-icon-button {
      &.cdk-focused {
        &.cdk-program-focused {
          background: $white;
        }
      }

      &.active {
        color: $logoCappuccine-color;
      }

      &.disabled {
        color: $inactive_color;
        background: none;
        pointer-events: none;
        cursor: default;
      }
    }

    &:disabled {
      width: 15px;
      cursor: default !important;
    }
  }

  &.btn-flat {
    color: $white;
    background: none;
  }

  &.combo-btn {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    border-bottom: 2px solid $darken_grey;
    border-radius: unset;
  }

  &.btn-circle {
    max-width: 38px;
    width: 38px;
    min-width: 38px;
    height: 38px;
    padding: 0 0.2rem;
    background: none;
    border-radius: 38px;

    .icon {
      margin-right: 0;
    }

    &::after {
      border-radius: 38px !important;
    }
  }

  &.mobile {
    &::after {
      background-color: rgba($darken_grey, 0.35);
    }

    &.entityCreationBtn {
      &::after {
        background-color: rgba($darken_grey, 0);
      }
    }
  }

  &.cancel,
  &.disabled {
    background-color: $caption !important;
  }

  &:not(.table-actionBtn, .mat-calendar-previous-button, .mat-calendar-next-button, .combo-btn) {
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.1);
      border-radius: $border_radius;
    }
  }

  &.btn-with-border {
    color: var(--accent_color);
    border: 1px solid var(--accent_color);
    border-radius: 38px;

    &::after {
      border-radius: 38px;
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &:focus {
    outline: 0;
  }

  &:not(.disabled) {
    &:hover {
      cursor: pointer;

      &::after {
        background-color: rgba($white, 0.2);
      }
    }

    &:active {
      &::after {
        background-color: rgba($black, 0.1);
      }
    }
  }

  ~ button {
    margin-left: 15px;
  }
}

button,
a.button {
  &.mat-menu-item,
  &.table-actionBtn {
    ~ button {
      margin-left: 0 !important;
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;

  &.space-between {
    justify-content: space-between;
  }

  button {
    margin-bottom: 0;
  }
}

.displayCriticalValueButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 28px;
  padding: 0 10px;
  font-weight: bold;
  color: $font_color_base;
  text-transform: none;
  background-color: lightgrey !important;
}

button:disabled,
.disableCriticalValueButton {
  cursor: not-allowed !important;
}

.displayCriticalValueButtonText {
  font-size: 14px;
}

mat-icon {
  &.displayCriticalValueButtonIcon {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
}
