@keyframes highlight {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes actionFadeRight {
  0% {
    transform: translateX(-40px);
  }

  100% {
    transform: translateX(0);
  }
}
