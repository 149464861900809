@import '../helpers/utils';

@mixin tag($color: $basic_grey, $line: false, $aggrega: false) {
  @if type-of($color) != 'color' {
    @error '$color must be a =< CSS3 color';
  }

  position: relative;
  max-width: 100%;
  height: 26px;
  padding: 0.2em 0.8em;
  font-size: 12px;
  line-height: 20px;
  color: darken($color, 10%);
  border-radius: $border_radius;

  @if $aggrega == true {
    margin-left: auto;
    margin-right: 0;
  }

  @if $line == false {
    display: inline;
    background-color: transparentize($color, 0.8);
  }

  @else {
    display: inline-flex;
    align-items: center;
    padding: 0.2em 0.5em;
    background-color: $white;
    border: 1px solid transparentize($color, 0.8);

    .line {
      display: block;
      max-width: 2px;
      width: 2px;
      min-width: 2px;
      height: 100%;
      margin-right: 5px;
      background-color: $color;
      border-radius: 3px;

      @if $aggrega == true {
        margin-right: 0;

        ~ .line {
          margin-right: 3px;
        }
      }
    }
  }

  .tag-label {
    max-width: calc(100% - 7px);
    @include ellipsis;
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.tag {
  @include tag();
}
