.os-win {
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border: 0 none #b4b4b4;
    border-radius: 81px;

    &:hover,
    &:active {
      background: #b4b4b4;
    }
  }

  ::-webkit-scrollbar-track {
    background: none;
    border: 0 none #efefef;
    border-radius: 0;

    &:hover {
      background: none;
    }

    &:active {
      background: none;
    }
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.ps {
  position: relative;
}
