.checkbox-group,
.radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.checkbox-container,
.radio-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .indexation-page & {
    color: $white;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &:not(.thesaurusAgg) {
    &:hover:not(.disabled) {
      cursor: pointer;

      .checkbox-mark,
      .radio-mark {
        display: block !important;
      }
    }
  }

  &.thesaurusAgg {
    .checkbox {
      &:hover:not(.disabled) {
        cursor: pointer;

        .checkbox-mark,
        .radio-mark {
          display: block !important;
        }
      }
    }
  }

  &.selected,
  input:checked {
    .checkbox,
    .radio,
    ~ .checkbox,
    ~ .radio {
      border-color: var(--accent_color) !important;

      .checkbox-mark,
      .radio-mark {
        display: block !important;
      }

      &.border-white {
        border-color: $white !important;
      }
    }
  }
}

.checkbox {
  position: relative;
  display: inline-block;
  max-width: 18px;
  min-width: 18px;
  width: 18px;
  max-height: 18px;
  min-height: 18px;
  height: 18px;
  margin-top: 0;
  margin-right: 8px;
  border: 1px solid $light_grey;
  border-radius: $border_radius;

  .indexation-page & {
    margin-top: 0;
  }

  &.mobile {
    border-color: $font_color_base;

    .checkbox-mark {
      background-color: var(--accent_color);

      &::before {
        background-color: var(--accent_color);
      }
    }
  }
}

.checkbox-mark {
  position: absolute;
  left: 4px;
  top: 6px;
  display: none;
  width: 2px;
  height: 8px;
  background-color: $white;
  border-radius: $border_radius;
  transform: rotate(-50deg);

  &::before {
    content: '';
    position: absolute;
    left: 2px;
    top: 6px;
    width: 10px;
    height: 2px;
    background-color: $white;
    border-radius: $border_radius;
  }
}

.radio {
  position: relative;
  display: inline-block;
  max-width: 14px;
  min-width: 14px;
  width: 14px;
  max-height: 14px;
  min-height: 14px;
  height: 14px;
  margin-top: 3px;
  margin-right: 8px;
  border: 1px solid $light_grey;
  border-radius: 100%;

  .indexation-page & {
    margin-top: 0;
  }

  &-mark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    width: 6px;
    height: 6px;
    margin: auto;
    background-color: $light_grey;
    border-radius: 100%;
  }
}
