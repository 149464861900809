@import './base';

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '@material-design-icons/font/outlined.css';

// Base layout come here, global styles to this file, or global components into base

html,
body {
  height: 100%;
  overflow: hidden;
}

html {
  font-family: $font_family;
  color: $font_color_base;
  background-color: $bg_color;
}

body {
  display: block;
  background-color: $white;

  .noUi-horizontal {
    .noUi-handle {
      top: -10px;
      right: -6px;
      width: 10px;
      height: 20px;

      &::after {
        top: 3px;
        left: 5px;
      }

      &::before {
        top: 3px;
        left: 2px;
      }
    }
  }

  .text-input-highlight-container {
    position: relative;

    .text-input-element {
      position: relative;
      z-index: 2;
      background: none;
    }

    .text-highlight-element {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      word-break: break-word;
      white-space: pre-wrap;
      color: rgba(0, 0, 0, 0);
      background: white;
      //overflow: hidden !important;
    }

    .text-highlight-tag {
      margin: -1px -3px;
      padding: 0 1px;
      line-height: 25px;
      border-radius: 8px;
      overflow-wrap: break-word;

      &.border-capuccine {
        border: 2px solid $logoCappuccine-color;
      }
    }
  }
}

button,
input,
select,
textarea {
  font-family: $font_family;
  color: $font_color_base;
}

// HACK: For angular rendering canvas to late
.app-pdf-thumbnail-wrap {
  canvas {
    display: block;
    width: 100%;
    box-shadow: 0 -1px 24px 0 rgba($black, 0.5);
  }
}

.mat-menu-panel {
  position: relative;
  background-color: $light_grey;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($light_grey, 0.2);
  }
}

.warningIcon {
  float: right;
  padding-left: 5px;

  &.white {
    color: $white;
  }

  &.green {
    color: $green;
  }

  &.orange {
    color: $orange;
  }
}

.fieldFormatTypeEmail {
  color: slateblue;

  &:hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.fieldFormatTypeUrl {
  color: deepskyblue;

  &:hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.fieldFormatTypePhone {
  color: lightblue;

  &:hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

@import './components/material-input';
