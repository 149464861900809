// F****** Edge from https://browserstrangeness.github.io/css_hacks.html
@mixin ifEdge {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin documentsEntities-switch {
  .documentsEntities-switch {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 20px 0 40px;

    @include min-screen($screen-desktop) {
      justify-content: start;
    }

    .routeItem {
      margin-right: 20px;

      a {
        display: inline-block;
        padding: 15px 20px;
        color: $darken_grey;
        background-color: $white;
        border: 1px solid $border_color;
        border-radius: 4px;

        &:hover {
          color: var(--accent_color);
        }
      }

      &.active {
        pointer-events: none;

        a {
          color: $white;
          background-color: var(--accent_color);
          border: none;
          box-shadow: 0 0 15px 0 var(--accent_color);
        }
      }
    }
  }
}

@mixin logoColors {
  .logo-colors {
    position: fixed;
    top: -15px;
    bottom: 0;
    left: -30px;
    display: flex;
    transform: rotate(5deg);

    @include max-screen(1400px) {
      transform: rotate(6deg);
    }

    .layer-1 {
      z-index: 5;
      width: 80px;
      height: 110vh;
      margin-left: 10px;
      background-image: linear-gradient(127deg, $logoCappuccine-color 0%, $logoOrange-color 30%, $logoBlue-color 100%);
      opacity: 0.9;
    }

    .layer-2 {
      z-index: 5;
      width: 20px;
      height: 110vh;
      background-image: linear-gradient(127deg, $logoCappuccine-color 0%, $logoOrange-color 25%, $logoBlue-color 65%);
      opacity: 0.6;
    }

    .layer-3 {
      z-index: 5;
      width: 15px;
      height: 110vh;
      background-image: linear-gradient(127deg, $logoCappuccine-color 0%, $logoOrange-color 25%, $logoBlue-color 65%);
      opacity: 0.3;
    }
  }
}

.separator {
  display: block;
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background-color: lighten($basic_grey, 40%);
}

.hide {
  display: none;
}

.no-marginLeft {
  margin-left: 0 !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.error,
.required {
  color: $error;
}

a {
  color: var(--accent_color);

  &:hover {
    color: $black;
    opacity: 1;
  }

  &:active {
    opacity: 1;
  }
}

.minScreen {
  display: none;

  @include max-screen(1200px) {
    display: flex;
  }
}

.maxScreen {
  @include max-screen(1200px) {
    display: none;
  }
}
