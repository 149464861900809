.tip {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;

  .tip-icon {
    min-width: 24px;
    margin-right: 10px;
    fill: $logoCappuccine-color;
  }

  .tip-text {
    margin: 0;
  }

  @include min-screen($screen-desktop) {
    margin-bottom: 0;
  }
}
