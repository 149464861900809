@font-face {
  font-family: $icons_family;
  src:
    url('#{$fonts_path}/icomoon/icomoon.ttf#{$icon_key}') format('truetype'),
    url('#{$fonts_path}/icomoon/icomoon.woff#{$icon_key}') format('woff'),
    url('#{$fonts_path}/icomoon/icomoon.svg#{$icon_key}#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  // use !important to prevent issues with browser extensions that change fonts
  speak: none;
  font-family: $icons_family !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  'time': \e903,
  'attach_file': \e226,
  'indexation': \e86d,
  'insert_drive_file': \e24d,
  'visibility': \e8f4,
  'reply': \e5da,
  'add': \e145,
  'arrow_back': \e5c4,
  'cancel': \e5c9,
  'chat': \e0b7,
  'close': \e5cd,
  'content_copy': \e14d,
  'mode_edit': \e254,
  'dashboard': \e871,
  'delete': \e872,
  'delete_sweep': \e16c,
  'extension': \e87b,
  'file_upload': \e2c6,
  'filter_list': \e152,
  'group_work': \e886,
  'inbox': \e156,
  'info_outline': \e88f,
  'keyboard_arrow_down': \e313,
  'keyboard_arrow_left': \e314,
  'keyboard_arrow_right': \e315,
  'keyboard_arrow_up': \e316,
  'local_offer': \e54e,
  'note_add': \e89c,
  'rotate_right': \e41a,
  'search': \e8b6,
  'settings': \e8b8,
  'view_list': \e8ef,
  'view_module': \e8f0,
  'zoom_in': \e8ff,
  'zoom_out': \e900,
  'zoom_out_map': \e56b,
  'hand-paper-o': \e902,
  'i-cursor': \e901,
  'dots': \e904,
  'user': \e971,
  'exit': \ea14,
  'warning': \ea07,
  'loop2': \ea2e,
  'menu': \e9bd,
  'sliders': \e905,
  'wrench': \e991,
  'share-2': \e906,
  'external-link': \e907,
  'check': \e908,
  'users': \e972,
  'stats-dots': \e99b
);

@function isUnicode($value) {
  @return str-index(to-lower-case($value), \e) == 0;
}

@function unicode($str) {
  //@if isUnicode($str) == null {
  //  //@error 'Icon value must be unicode start with \e';
  //  @error 'Icon value in error is: ' + $str;
  //  @return null;
  //}
  //
  //@else {
  @return unquote("\"") + $str + unquote("\"");
  //}
}

@each $icon-name, $icon-value in $icons {
  @if type-of($icons) != 'map' {
    @error '$icons must be a map';
  }

  @if unicode($icon-value) != null {
    .#{$icon_prefix}#{$icon-name}::before {
      content: unicode($icon-value);
    }
  }
}

.privacy_not_public {
  width: 20px !important;
  height: auto !important;
  padding: 4px 0 !important;
  font-size: medium;
}
